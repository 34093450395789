@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "HelveticaWorldBold";
    src: url("./assets/fonts/HelveticaWorldBold.ttf") format("truetype");
}

/* Apply custom cursor to the whole document */
/* body,a,i,button {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 448 512'%3E%3Cpath d='M128 40c0-22.1 17.9-40 40-40s40 17.9 40 40V188.2c8.5-7.6 19.7-12.2 32-12.2c20.6 0 38.2 13 45 31.2c8.8-9.3 21.2-15.2 35-15.2c25.3 0 46 19.5 47.9 44.3c8.5-7.7 19.8-12.3 32.1-12.3c26.5 0 48 21.5 48 48v48 16 48c0 70.7-57.3 128-128 128l-16 0H240l-.1 0h-5.2c-5 0-9.9-.3-14.7-1c-55.3-5.6-106.2-34-140-79L8 336c-13.3-17.7-9.7-42.7 8-56s42.7-9.7 56 8l56 74.7V40zM240 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304zm48-16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304z'/%3E%3C/svg%3E"), auto;
}
button {
  cursor: default;
} */
.page-content {
    position: relative;
    z-index: 1; /* Ensure content is below the fluid simulation but above other backgrounds */
}
#tsparticles {
    background-color: red;
}
/* Define basic font settings and color variables */
#tr__fluid__anim {
    background-color: transparent !important;
}
html {
    scroll-behavior: smooth;
}
/* Width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: black;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #bbbbbb;
}

.cardBg {
    background: rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 5px 7px 35px 0px white;
    -moz-box-shadow: 5px 7px 35px 0px white;
    box-shadow: 5px 7px 35px 0px white;
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
}

/* Add more styles or media queries as per your need */

/* Responsive Typography */

.panel {
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.red {
    height: 100vh;
    min-height: 100vh;
    background-color: red;
}
.dark {
    height: 100vh;
    min-height: 100vh;
    background-color: rgb(18, 18, 18);
}
.orange {
    height: 100vh;
    min-height: 100vh;
    background-color: rgb(232, 97, 13);
}
.purple {
    height: 50vh;
    min-height: 50vh;
    background-color: purple;
}

.boxes-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.linearBg {
    position: relative;
    z-index: 1;

    background-attachment: fixed; /* Key for parallax effect */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; /* Cover ensures the background covers the div */
    background-image: linear-gradient(
        to right top,
        #220530,
        #1a0930,
        #130b2e,
        #0c0d2b,
        #060e28,
        #020f25,
        #001022,
        #00101e,
        #00101a,
        #000f16,
        #000e11,
        #010c0c
    );
}
.teamCardBg {
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 32px 0 #c5fcfc;
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);

    animation: circularMotion 5s infinite linear;
}

/* Base state: applying transition properties here ensures smooth changes */
.navbarBase {
    transition: background 0.3s ease, box-shadow 0.3s ease,
        backdrop-filter 0.3s ease, border-bottom 0.3s ease;
    background: transparent; /* Default state */
    box-shadow: none; /* Default state */
    backdrop-filter: none; /* Default state */
    border-bottom: transparent; /* Default state */
    /* Apply other base styles here */
}

/* Scrolled state */
.navbarBg {
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 0px 12px 0 #bf5331;
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    /* Remove or adjust the animation property if it interferes with the transition */
    /* animation: navbarMotion 5s infinite linear; */
}
.scroll-container {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

@keyframes navbarMotion {
    0% {
        border-bottom: 3px solid rgba(31, 38, 135, 0.37);
        box-shadow: 10px 0 302px 0 rgba(31, 38, 135, 0.37);
    }
    25% {
        border-bottom: 3px solid rgba(31, 38, 135, 0.37);
        box-shadow: 0 10px 32px 0 rgba(31, 38, 135, 0.37);
    }
    50% {
        border-bottom: 3px solid rgba(31, 114, 135, 0.37);
        box-shadow: -10px 0 302px 0 rgba(31, 114, 135, 0.37);
    }
    75% {
        border-bottom: 3px solid rgba(111, 31, 135, 0.37);
        box-shadow: -10px 0 302px 0 rgba(111, 31, 135, 0.37);
    }
    100% {
        border-bottom: 3px solid rgba(31, 38, 135, 0.37);
        box-shadow: -10px 0 302px 0 rgba(31, 38, 135, 0.37);
    }
}
